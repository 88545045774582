import { AppEnvironment } from '@dev-portal/environments';
import { baseEnvironmentValues, scope } from './config/base-environment-values';

const publicApiHost: string = 'http://localhost:4444';
const authServerUrl: string = `http://localhost:6060/api/v1/auth/oidc`;

export const environment: AppEnvironment = {
  ...baseEnvironmentValues,
  dev: true,
  name: 'Development',
  host: 'https://developers.dev.nursa.com/',
  apiUrl: `${publicApiHost}/api/oidc`,
  authServerUrl: authServerUrl,
  auth0Config: {
    domain: authServerUrl,
    clientId: 'Jb3hZC5VGQNTZZ3EjNeiXYDcN32iYjzX',
    issuer: 'https://auth.local.nursa.com/',
    useRefreshTokens: true,
    cacheLocation: 'localstorage',
    authorizationParams: {
      scope,
      redirect_uri: `${window.location.origin}/applications`,
      audience: 'https://public-api.dev.nursa.com/'
    },
    httpInterceptor: {
      allowedList: [{ uri: `${publicApiHost}/api/oidc/*` }]
    }
  }
};
