import { AppEnvironment } from '@dev-portal/environments';

export const baseEnvironmentValues: Pick<AppEnvironment, 'paths' | 'version'> = {
  version: '1.1.1',
  paths: {
    images: '/assets/images'
  }
};

export const scope =
  'openid profile offline_access role oidc-application:read oidc-application:write';
